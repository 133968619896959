<template>
    <v-container grid-list-md text-xs-center fluid mb-16>
        <h1>Liberação de Resultado</h1>
        <v-divider class="mb-4"></v-divider>

        <FilterDateClientStore @changeDate="changeDate" @changeClient="changeClient" @changeStore="changeStore" />

        <v-layout wrap>
            <v-flex xs12 md6> </v-flex>
            <v-flex xs12 md6>
                <v-text-field :label="$t('Filtrar...')" v-model="filterName" clearable />
            </v-flex>
        </v-layout>

        <v-data-table :headers="headers" :items="listWithFilter" class="elevation-2" sort-by.sync="name">
            <template v-slot:[`item.createdAt`]="{ item }">
                <v-chip class="ma-0" outlined color="success" v-if="item.createdAt"> {{ $moment(item.createdAt).format("DD/MM/YYYY") }} </v-chip>
            </template>

            <template v-slot:[`item.status`]="{ item }">
                <v-chip class="ma-0" outlined v-if="item.status == 2" color="success"> Laudo Completo </v-chip>
                <v-chip class="ma-0" outlined v-else-if="item.status == 1" color="warning"> Alguns exames Disponiveis </v-chip>
                <v-chip class="ma-0" outlined v-else color="error"> Aguarde... </v-chip>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <v-btn class="ma-2" color="info" dark small @click="print(item)" v-if="item.status == 2"> <v-icon dark small left>print</v-icon> Imprimir </v-btn>
                <v-btn class="ma-2" :color="item.status == 2 ? 'grey' : 'green'" dark small @click="release(item)">
                    <v-icon dark small left>visibility</v-icon>
                    Detalhes
                </v-btn>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import FilterDateClientStore from "@/components/shared/FilterDateClientStore";

export default {
    components: { FilterDateClientStore },

    data() {
        return {
            filterName: null,
            items: [],
            dates: [],
            selectedStore: null,
            selectedClient: null,
            headers: [
                { text: this.$t("Identificação"), value: "id", sortable: false },
                { text: this.$t("Unidade"), value: "store", sortable: true },
                { text: this.$t("Cliente"), value: "client", sortable: true },
                { text: this.$t("Data"), value: "createdAt", align: "center", sortable: true },
                { text: this.$t("Status"), value: "status", align: "center", sortable: true },
                { text: this.$t("Ações"), value: "actions", align: "right", sortable: true },
            ],
        };
    },

    computed: {
        listWithFilter() {
            let ret = this.items;
            if (this.filterName) {
                let exp = new RegExp(
                    this.filterName
                        .trim()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, ""),
                    "i"
                );
                ret = ret.filter(
                    (item) =>
                        typeof item === "object" &&
                        item !== null &&
                        exp.test(
                            JSON.stringify(Object.values(item))
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")
                        )
                );
            }
            return ret;
        },
    },

    methods: {
        changeClient(event) {
            this.selectedClient = event;
            this.search();
        },
        changeDate(event) {
            this.dates = event;
            this.search();
        },
        changeStore(event) {
            this.selectedStore = event;
            this.search();
        },
        search() {
            let query = "";

            if (this.selectedClient) {
                query = `?client=${this.selectedClient.id}`;
            } else {
                query = this.dates.length == 2 ? `?from=${this.dates[0]}&to=${this.dates[1]}` : `?from=${this.dates[0]}&to=${this.dates[0]}`;
            }
            if (this.selectedStore) {
                query += `&store=${this.selectedStore.id}`;
            }

            this.$http
                .get(`results${query}&timezone=-3`)
                .then((result) => (this.items = result))
                .catch(() => (this.items = []));
        },
        release(item) {
            this.$router.push({ path: `/results/${item.id}` });
        },
        print(item) {
            this.$http.getFile(`results/print/${item.id}`, `resultado(${item.id})(${item.client})(${this.$moment().format("YYYYMMDD HHmmss")})`).catch((error) => {
                this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao gerar PDF."));
            });
        },
    },
};
</script>